exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-ml-jsx": () => import("./../../../src/pages/ai-ml.jsx" /* webpackChunkName: "component---src-pages-ai-ml-jsx" */),
  "component---src-pages-api-management-jsx": () => import("./../../../src/pages/api-management.jsx" /* webpackChunkName: "component---src-pages-api-management-jsx" */),
  "component---src-pages-application-modernization-jsx": () => import("./../../../src/pages/application-modernization.jsx" /* webpackChunkName: "component---src-pages-application-modernization-jsx" */),
  "component---src-pages-architecture-engineering-jsx": () => import("./../../../src/pages/architecture-engineering.jsx" /* webpackChunkName: "component---src-pages-architecture-engineering-jsx" */),
  "component---src-pages-automobile-jsx": () => import("./../../../src/pages/automobile.jsx" /* webpackChunkName: "component---src-pages-automobile-jsx" */),
  "component---src-pages-become-a-partner-jsx": () => import("./../../../src/pages/become-a-partner.jsx" /* webpackChunkName: "component---src-pages-become-a-partner-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-cloud-service-jsx": () => import("./../../../src/pages/cloud-service.jsx" /* webpackChunkName: "component---src-pages-cloud-service-jsx" */),
  "component---src-pages-data-engineering-jsx": () => import("./../../../src/pages/data-engineering.jsx" /* webpackChunkName: "component---src-pages-data-engineering-jsx" */),
  "component---src-pages-data-science-jsx": () => import("./../../../src/pages/data-science.jsx" /* webpackChunkName: "component---src-pages-data-science-jsx" */),
  "component---src-pages-devops-jsx": () => import("./../../../src/pages/devops.jsx" /* webpackChunkName: "component---src-pages-devops-jsx" */),
  "component---src-pages-explore-jsx": () => import("./../../../src/pages/explore.jsx" /* webpackChunkName: "component---src-pages-explore-jsx" */),
  "component---src-pages-fintech-jsx": () => import("./../../../src/pages/fintech.jsx" /* webpackChunkName: "component---src-pages-fintech-jsx" */),
  "component---src-pages-full-stack-development-jsx": () => import("./../../../src/pages/full-stack-development.jsx" /* webpackChunkName: "component---src-pages-full-stack-development-jsx" */),
  "component---src-pages-gaming-jsx": () => import("./../../../src/pages/gaming.jsx" /* webpackChunkName: "component---src-pages-gaming-jsx" */),
  "component---src-pages-get-in-touch-jsx": () => import("./../../../src/pages/get-in-touch.jsx" /* webpackChunkName: "component---src-pages-get-in-touch-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurtech-jsx": () => import("./../../../src/pages/insurtech.jsx" /* webpackChunkName: "component---src-pages-insurtech-jsx" */),
  "component---src-pages-maritime-jsx": () => import("./../../../src/pages/maritime.jsx" /* webpackChunkName: "component---src-pages-maritime-jsx" */),
  "component---src-pages-our-partners-jsx": () => import("./../../../src/pages/our-partners.jsx" /* webpackChunkName: "component---src-pages-our-partners-jsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-platform-and-product-development-jsx": () => import("./../../../src/pages/platform-and-product-development.jsx" /* webpackChunkName: "component---src-pages-platform-and-product-development-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-software-development-consulting-jsx": () => import("./../../../src/pages/software-development-consulting.jsx" /* webpackChunkName: "component---src-pages-software-development-consulting-jsx" */),
  "component---src-pages-software-product-development-jsx": () => import("./../../../src/pages/software-product-development.jsx" /* webpackChunkName: "component---src-pages-software-product-development-jsx" */),
  "component---src-pages-user-experience-design-jsx": () => import("./../../../src/pages/user-experience-design.jsx" /* webpackChunkName: "component---src-pages-user-experience-design-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

